// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open

// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
	position: relative;
	display: inline-flex;
	min-height: (1rem * $line-height-base);
	padding-left: $custom-control-gutter;
	margin-right: $custom-control-spacer-x;
}

.custom-control-input {
	position: absolute;
	z-index: -1; // Put the input behind the label so it doesn't overlay text
	opacity: 0;

	&:checked ~ .custom-control-indicator {
		color: $custom-control-indicator-checked-color;
		@include gradient-bg($custom-control-indicator-checked-bg);
		@include box-shadow($custom-control-indicator-checked-box-shadow);
	}

	&:focus ~ .custom-control-indicator {
		// the mixin is not used here to make sure there is feedback
		box-shadow: $custom-control-indicator-focus-box-shadow;
	}

	&:active ~ .custom-control-indicator {
		color: $custom-control-indicator-active-color;
		@include gradient-bg($custom-control-indicator-active-bg);
		@include box-shadow($custom-control-indicator-active-box-shadow);
	}

	&:disabled {
		~ .custom-control-indicator {
			background-color: $custom-control-indicator-disabled-bg;
		}

		~ .custom-control-description {
			color: $custom-control-description-disabled-color;
		}
	}
}

// Custom indicator
//
// Generates a shadow element to create our makeshift checkbox/radio background.

.custom-control-indicator {
	position: absolute;
	top: (($line-height-base - $custom-control-indicator-size) / 2);
	left: 0;
	display: block;
	width: $custom-control-indicator-size;
	height: $custom-control-indicator-size;
	pointer-events: none;
	user-select: none;
	background-color: $custom-control-indicator-bg;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: $custom-control-indicator-bg-size;
	@include box-shadow($custom-control-indicator-box-shadow);
}

// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
	.custom-control-indicator {
		@include border-radius($custom-checkbox-indicator-border-radius);
	}

	.custom-control-input:checked ~ .custom-control-indicator {
		background-image: $custom-checkbox-indicator-icon-checked;
	}

	.custom-control-input:indeterminate ~ .custom-control-indicator {
		background-color: $custom-checkbox-indicator-indeterminate-bg;
		background-image: $custom-checkbox-indicator-icon-indeterminate;
		@include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
	}
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
	.custom-control-indicator {
		border-radius: $custom-radio-indicator-border-radius;
	}

	.custom-control-input:checked ~ .custom-control-indicator {
		background-image: $custom-radio-indicator-icon-checked;
	}
}

// Layout options
//
// By default radios and checkboxes are `inline-block` with no additional spacing
// set. Use these optional classes to tweak the layout.

.custom-controls-stacked {
	display: flex;
	flex-direction: column;

	.custom-control {
		margin-bottom: $custom-control-spacer-y;

		+ .custom-control {
			margin-left: 0;
		}
	}
}

// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// http://primercss.io.
//

.custom-select {
	display: inline-block;
	max-width: 100%;
	height: $input-height;
	padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
	line-height: $custom-select-line-height;
	color: $custom-select-color;
	vertical-align: middle;
	background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
	background-size: $custom-select-bg-size;
	border: $custom-select-border-width solid $custom-select-border-color;
	@if $enable-rounded {
		border-radius: $custom-select-border-radius;
	} @else {
		border-radius: 0;
	}
	appearance: none;

	&:focus {
		border-color: $custom-select-focus-border-color;
		outline: none;
		@include box-shadow($custom-select-focus-box-shadow);

		&::-ms-value {
			// For visual consistency with other platforms/browsers,
			// supress the default white text on blue background highlight given to
			// the selected option text when the (still closed) <select> receives focus
			// in IE and (under certain conditions) Edge.
			// See https://github.com/twbs/bootstrap/issues/19398.
			color: $input-color;
			background-color: $input-bg;
		}
	}

	&[multiple] {
		height: auto;
		background-image: none;
	}

	&:disabled {
		color: $custom-select-disabled-color;
		background-color: $custom-select-disabled-bg;
	}

	// Hides the default caret in IE11
	&::-ms-expand {
		opacity: 0;
	}
}

.custom-select-sm {
	height: $custom-select-height-sm;
	padding-top: $custom-select-padding-y;
	padding-bottom: $custom-select-padding-y;
	font-size: $custom-select-font-size-sm;
}

// File
//
// Custom file input.

.custom-file {
	position: relative;
	display: inline-block;
	max-width: 100%;
	height: $custom-file-height;
	margin-bottom: 0;
}

.custom-file-input {
	min-width: $custom-file-width;
	max-width: 100%;
	height: $custom-file-height;
	margin: 0;
	opacity: 0;

	&:focus ~ .custom-file-control {
		box-shadow: $custom-file-focus-box-shadow;
	}
}

.custom-file-control {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 5;
	height: $custom-file-height;
	padding: $custom-file-padding-y $custom-file-padding-x;
	line-height: $custom-file-line-height;
	color: $custom-file-color;
	pointer-events: none;
	user-select: none;
	background-color: $custom-file-bg;
	border: $custom-file-border-width solid $custom-file-border-color;
	@include border-radius($custom-file-border-radius);
	@include box-shadow($custom-file-box-shadow);

	@each $lang, $text in map-get($custom-file-text, placeholder) {
		&:lang(#{$lang}):empty::after {
			content: $text;
		}
	}

	&::before {
		position: absolute;
		top: -$custom-file-border-width;
		right: -$custom-file-border-width;
		bottom: -$custom-file-border-width;
		z-index: 6;
		display: block;
		height: $custom-file-height;
		padding: $custom-file-padding-y $custom-file-padding-x;
		line-height: $custom-file-line-height;
		color: $custom-file-button-color;
		@include gradient-bg($custom-file-button-bg);
		border: $custom-file-border-width solid $custom-file-border-color;
		@include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
	}

	@each $lang, $text in map-get($custom-file-text, button-label) {
		&:lang(#{$lang})::before {
			content: $text;
		}
	}
}
