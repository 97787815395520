// stylelint-disable declaration-no-important, selector-no-qualifying-type

// Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request:
// http://www.phpied.com/delay-loading-your-print-css/
// ==========================================================================

@if $enable-print-styles {
	@media print {
		*,
		*::before,
		*::after {
			// Bootstrap specific; comment out `color` and `background`
			//color: #000 !important; // Black prints faster: http://www.sanbeiji.com/archives/953
			text-shadow: none !important;
			//background: transparent !important;
			box-shadow: none !important;
		}

		a,
		a:visited {
			text-decoration: underline;
		}

		// Bootstrap specific; comment the following selector out
		//a[href]::after {
		//  content: " (" attr(href) ")";
		//}

		abbr[title]::after {
			content: " (" attr(title) ")";
		}

		// Bootstrap specific; comment the following selector out
		//
		// Don't show links that are fragment identifiers,
		// or use the `javascript:` pseudo protocol
		//

		//a[href^="#"]::after,
		//a[href^="javascript:"]::after {
		// content: "";
		//}

		pre {
			white-space: pre-wrap !important;
		}
		pre,
		blockquote {
			border: $border-width solid #999999; // Bootstrap custom code; using `$border-width` instead of 1px
			page-break-inside: avoid;
		}

		//
		// Printing Tables:
		// http://css-discuss.incutio.com/wiki/Printing_Tables
		//

		thead {
			display: table-header-group;
		}

		tr,
		img {
			page-break-inside: avoid;
		}

		p,
		h2,
		h3 {
			orphans: 3;
			widows: 3;
		}

		h2,
		h3 {
			page-break-after: avoid;
		}

		// Bootstrap specific changes start

		// Bootstrap components
		.navbar {
			display: none;
		}
		.badge {
			border: $border-width solid #000000;
		}

		.table {
			border-collapse: collapse !important;

			td,
			th {
				background-color: #ffffff !important;
			}
		}
		.table-bordered {
			th,
			td {
				border: 1px solid #dddddd !important;
			}
		}

		// Bootstrap specific changes end
	}
}
